.np-data-table-actions {
    display: flex;
    flex-direction: row;
    gap: 4rem;
}

.finish-purchase-button-disabled {
    color: rgba(195, 193, 193, 0.563) !important;
    box-shadow: none;
    background-color: rgb(102, 136, 102) !important;
}
