@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
    position: relative;

    font-family: -apple-system, "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --primary-color: #293a4ed6;
}

body, html {
    font-size: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    html {
        font-size: 60%;
    }
}

code {
    font-family: "Poppins", sans-serif;
}
