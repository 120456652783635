.create-department-btn-disabled {
    color: rgba(195, 193, 193, 0.563) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.238) !important;
}

.department-members {
    display: flex;
    gap: 0.5rem;
}
