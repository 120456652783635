.create-cashier-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 1rem;

    .basic-info {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}
