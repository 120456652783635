.create-purchase-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 1rem;

    .basic-info,
    .purchase-stock,
    .purchase-type,
    .item-category {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .purchase-summary {
        .purchase-summary-cost {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            strong {
                color: #31d239;
            }
        }
    }
}
